import {
  ref,
  computed,
} from '@vue/composition-api'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const users = ref({
  listBasic: [],
})

export default function useUsersMany() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { http } = useApollo()
  // const loading = ref(false)

  async function getManyUsersBasic() {
    await http.value.query({
      query: gql`
        query getManyUsersBasic {
          ${frontSchema}_users {
            id
            name
            created_at
            avatar
          }
        }
      `,
    })
      .then(result => {
        users.value.listBasic = result.data[`${frontSchema}_users`]
      })
      .catch(err => console.error('getManyUser', err))
    // return user
  }

  async function getManyUsersFull() {
    await http.value.query({
      query: gql`
        query getUserSingle {
          ${frontSchema}_users {
            avatar
            createdAt
            id
            lastSeen
            name
            plan
            role
            stripe_id
            country
            city
          }
        }
      `,
    })
      .then(() => {
        // console.log('dataReturned', result)
      })
      .catch(err => console.error('getManyUsersFull', err))
    // return user
  }

  return {
    usersListBasic: computed(() => users.value.listBasic),
    someLeaders: amount => users.value.listBasic.slice(0, amount),
    getManyUsersBasic,
    getManyUsersFull,
  }
}
