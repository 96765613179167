<template>
  <div class="leader-grid-wrap">
    <div class="leader-margin-right">
      <div :class="'leader-grid'">
        <Card
          v-for="leader in someLeaders(5)"
          :key="leader.id"
          class="leader-wrap"
        >
          <div class="leader-info">
            <img class="leader-avatar" :src="imgWithTransforms({ path: leader.avatar, type: ['avatar', 'large'] })" />
            <div class="metric-smalls">
              <div class="metric-small">{{ leader.rank }}</div>
              <div class="metric-small">{{ leader.accuracy }}</div>
            </div>
            <div class="metric-smalls">
              <div class="metric-small">
                <span class="large-text bold">{{ leader.totalTrades }} 123%</span>
                <span class="small-text"> Total Return </span>
              </div>
              <div class="metric-small"> {{ leader.totalReturn }}</div>
            </div>
            <div
              class="button primary-outline"
            >
              {{ leader.name }}
            </div>
          </div>
        </Card>
        <!-- <Modal
              v-model="leader.show"
              @confirm="confirm"
              @cancel="cancel"
            >
              <div class="leader-title-wrap">
                <h3 class="m0">
                  {{ leader.name }}
                </h3>
              </div>
              <p v-html="leader.name" />
            </Modal> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Modal from '../broadcast/appModal.vue'
import useUsersMany from '@/composables/users/useUsersMany'
import useCloudinary from '@/composables/useCloudinary'
import Card from '../appCard.vue'

export default {
  components: {
    Card,
    // Modal,
    // Leader,
  },
  props: {
    gridType: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { someLeaders } = useUsersMany()
    const { imgWithTransforms } = useCloudinary()
    return {
      someLeaders,
      imgWithTransforms,
    }
  },
  methods: {
    confirm() {
      // some code...
      this.show = false
    },
    cancel(close) {
      // some code...
      close()
    },
  },
}
</script>

<style lang="scss">

.small-text {
  font-size: 10px;
  font-weight: 500;
}

.large-text {
  font-size: 1.4rem;
  &.bold {
    font-weight: 600;
  }
}

.leader-grid-wrap {
  background-color: lightblue;
  padding: 2rem 0 2rem 2rem;
  border-radius: 8px;
  overflow: scroll;
  &::-webkit-scrollbar:horizontal {
    display: block !important;
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-thumb {
    margin-top: 1rem;
    background-color: #5a5a5a;
    border-radius: 3rem;
    border: 4px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 93%, 0.356);
    // border-left: 1px solid rgb(34, 34, 34);
  }
  .leader-margin-right {
    display: inline-block;
    margin-right: 2rem;
    .leader-grid {
      display: grid;
      height: 100%;
      gap: 2rem;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
    }
  }
}

.leader-wrap {
  position: relative;
  width: 100%;
  min-width: 320px;
  margin-top: 80px;
  display: flex;
  justify-content: start;
  // For Creation & Edit pages
  .leader-avatar {
    padding: 5px;
    position: absolute;
    background: white;
    top: -80px;
    left: 0;
    right: 0;
    margin: auto;
    width: 160px;
    height: 160px;
  }
  .leader-info {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    .metric-smalls {
      display: flex;
      width: 100%;
      height: 100px;
      margin-bottom: 1rem;
      gap: 1rem;
      .metric-small {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        background: hsla(195, 53%, 79%, 0.5);
        border-radius: 6px;
      }
    }
    // .leader-name {
    //   padding: 1rem;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 100%;
    //   background: hsla(195, 53%, 79%, 0.5);
    //   border-radius: 6px;
    // }
  }
  .body-wrap {
    display: flex;
    flex-direction: column;
  }
}

</style>
