<template>
  <div class="leaderboard-wrap">
    <div class="leader-prize">
      <div class="leader-prize-card">
        <div id="leaderPrizeBar" />
        <div class="leader-prize-content">
          <div class="d-flex">
            <h1 class="m-0">
              Time Left
            </h1>
            <button
              v-if="$auth.user.role === 'admin'"
              class="ml-1"
              @click="updateLastMonthLeader"
            >update last month (admin)</button>
          </div>
          <div id="leaderCountdown" />
        </div>
      </div>
    </div>
    <div class="leaderboard-grid">
      <LeaderboardGrid />
      <div class="leaderboard-top100">
        <GridSetup
          :processed-data="leaders"
          :pagination="false"
        />
      </div>
      <div class="leaderboard-prizes"> 🏆 Prizes coming soon</div>
      <div class="leaderboard-past-winners"> 🎖️ Past winners coming soon</div>
    </div>
  </div>
  <!-- <LeaderboardData
      :pagination="false"
      :search-filter="gridFilter"
    /> -->
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useUsersMany from '@/composables/users/useUsersMany'
import LeaderboardGrid from '@/views/components/leaderboard/LeaderboardGrid.vue'
import GridSetup from '../../components/agGrid/GridSetup.vue'

export default {
  name: 'Leaderboard',
  components: {
    LeaderboardGrid,
    GridSetup,
  },
  directives: { Ripple },
  setup() {
    const { getManyUsersBasic, usersListBasic: leaders } = useUsersMany()
    getManyUsersBasic()
    console.log('uzer', leaders)
    return {
      leaders,
    }
  },
  data() {
    return {
      barColor: null,
    }
  },
  computed: {
    chart() {
      return this.$isDark.value ? {
        background: 'rgba(71, 183,132,.5)',
        text: 'white',
        grid: 'white',
        dataset1: 'blue',
        dataset2: 'green',
        dataset3: 'orange',
      } : {
        background: 'red',
        text: 'white',
        grid: 'white',
        dataset1: 'blue',
        dataset2: 'green',
        dataset3: 'orange',
      }
    },
  },
  mounted() {
    this.setPrizeBarLength()
    this.initializeCountdown()
  },
  methods: {
    initializeCountdown() {
      const clock = document.getElementById('leaderCountdown')
      this.countdownInterval = setInterval(() => {
        const time = this.timeLeftInMonth()
        clock.innerHTML = `${time.days} days, ${time.hours}h-${time.minutes}m-${time.seconds}s left`
        if (time.total <= 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)
    },
    timeLeftInMonth() {
      const today = new Date()
      const currentYear = today.getUTCFullYear()
      const currentMonth = today.getUTCMonth()
      const startTime = new Date(currentYear, currentMonth, 0)
      const endTime = new Date(currentYear, currentMonth + 1, 0)
      const duration = endTime - startTime

      // time remaining
      const timeLeft = Date.parse(endTime) - Date.parse(new Date())
      const seconds = Math.floor((timeLeft / 1000) % 60)
      const minutes = Math.floor((timeLeft / 1000 / 60) % 60)
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24)
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      return {
        duration,
        timeLeft,
        days,
        hours,
        minutes,
        seconds,
      }
    },
    setPrizeBarLength() {
      const time = this.timeLeftInMonth()
      const timeElapsed = time.duration - time.timeLeft
      const percentElapsed = Math.round((timeElapsed / time.duration) * 100)
      const bar = document.getElementById('leaderPrizeBar')
      bar.style.width = `${percentElapsed}%`
    },
    async updateLastMonthLeader() {
      const token = await this.$auth.getTokenSilently()
      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_MLFX_API_BASE}/graph-cms/last-month-leaderboard`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      }
      this.$axios.request(options)
        .then(() => {
          // feedback
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="scss" >

@use '../../components/agGrid/components/optionsBar';

@media (min-width: 1600px) {
  .leaderboard-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(160px, 1fr);
    .leader-grid-wrap {
      grid-area: 1 / 1 / span 1 / span 2;
    }
    .leaderboard-top100 {
      grid-area: 1 / 3 / span 2 / span 3;
    }
    .leaderboard-prizes {
      grid-area: 2 / 1 / span 0 / span 1;
    }
    .leaderboard-past-winners {
      grid-area: 2 / 2 / span 0 / span 1;
    }
  }
}

@media (max-width: 1600px) {
  .leaderboard-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(160px, 1fr);
    .leader-grid-wrap {
      grid-area: 1 / 1 / span 1 / span 2;
    }
    .leaderboard-top100 {
      grid-area: 1 / 3 / span 3 / span 3;
    }
    .leaderboard-prizes {
      grid-area: 2 / 1 / span 1 / span 2;
    }
    .leaderboard-past-winners {
      grid-area: 3 / 1 / span 1 / span 2;
    }
  }
}

@media (max-width: 1000px) {
  .leaderboard-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(260px, auto);
    .leader-grid-wrap {
      grid-area: 1 / 1 / span 1 / span 2;
    }
    .leaderboard-top100 {
      grid-area: 2 / 1 / span 2 / span 2;
    }
    .leaderboard-prizes {
      grid-area: 4 / 1 / span 1 / span 2;
    }
    .leaderboard-past-winners {
      grid-area: 5 / 1 / span 1 / span 2;
    }
  }
}

.leaderboard-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  .leaderboard-grid {
    display: grid;
    gap: 2rem;
    // @media (max-width: 100px) {
    //   grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(400px, 2.5fr);
    // }
    .leaderboard-prizes {
      // min-width: 360px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e6e6e6;
      border-radius: 8px;
    }
    .leaderboard-past-winners {
      // min-width: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e6e6e6;
      border-radius: 8px;
    }
    .leaderboard-top100 {
      // min-width: 600px;
      background: #e6e6e6;
      overflow: hidden;
      border: 1px solid rgb(224, 224, 224);
      border-radius: 8px;
    }
  }
}

.about-leader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(242, 255, 247);
  .about-leader-button {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 24px 0 rgb(43 43 43 / 10%);
    border: 1px solid rgb(235, 235, 235);
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 1rem;
  }
}

.leader-prize {
  margin-bottom: 2rem;
  .leader-prize-card {
    position: relative;
    overflow: hidden;
    border-radius: 0.6rem;
    border: 1px solid rgb(223, 223, 223);
    color: black;
    font-weight: 900;
    background-color: #ffffff96;
    box-shadow: 0 4px 24px 0 rgb(43 43 43 / 10%);
    opacity: 0.8;
    #leaderPrizeBar {
      position: absolute;
      height: 100%;
      background-color: #ffffff96;
      background: linear-gradient(135deg, #28c87055 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #28c870 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #28c87055 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #28c870 25%, #f6f6f6 25%) 0px 0/ 20px 20px;
    }
    .leader-prize-content {
      color: rgb(39, 39, 39);
      padding: 10px 10px 10px 12px;
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: hsla(0, 0%, 100%, 0.7);
    }
  }
}

// leader percent bar
.leader-percent-bar {
  display: flex;
  align-items: center;
  position: static;
  top: calc(50% - 10px);
  height: 32px;
  width: 100%;
  background-color: hsl(0, 0%, 95%);
  border-radius: 0 1rem 1rem 0;
  #percentBarRed {
    background-color: rgb(255, 191, 191);
    height: 32px;
  }

  #percentBarOrange {
    background-color: hsl(41, 100%, 87%);
    height: 32px;
  }

  #percentBarGreen {
    background-color: hsl(138, 100%, 84%);
    height: 32px;
  }
  .percent-bar-value {
    position: absolute;
    padding-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    z-index: 100;
  }
}

.leader-cards {
  display: grid;
  grid-template-columns: repeat(6, minmax(200px, 1fr));
  column-gap: 2rem;
  padding: 1rem 1rem 2rem 2rem;
  .leader-card-body {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    align-content: stretch;
    box-shadow: 0 4px 24px 0 rgb(43 43 43 / 10%);
    background-color: white;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.6rem;
    border: 1px solid rgb(223, 223, 223);
    .leader-content {
      width: 100%;
      align-items: left;
    }
    .leader-cover {
      z-index: 0;
      top: 0;
      width: 100%;
      height: calc(100px - 1rem);
    }
    .leader-cover-overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      height: 100px;
      background-position: center;
    }
  }
}
.leader-avatar {
  z-index: 2;
  width: 60px;
  border-radius: 100rem;
  &.featured {
    margin-top: -50px;
    border: 3px solid rgb(73, 73, 73);
    width: 94px;
  }
  &.small {
    border: 1px solid rgb(73, 73, 73);
    width: 36px;
  }
}

label {
  font-weight: normal !important;
}

</style>
